@import "./variables";
body {
  font-family: Montserrat-Regular;
  color: $darkTextColor;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.MuiDrawer-paper {
  background: linear-gradient(180deg, #803240 0%, #01172f 100%);
  color: white !important;
  .MuiListItemIcon-root {
    color: white !important;
  }
}
a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.langBtn {
  color: $primaryColor;
  text-decoration: none;
  font-family: NeoSansArabicBold !important;
}

.MuiButton-outlinedPrimary {
  border: 2px solid $primaryColor !important;
  &:hover {
    border: 2px solid $primaryColor !important;
  }
}

.MuiButton-outlinedSecondary {
  border: 2px solid $secondaryColor !important;
  &:hover {
    border: 2px solid $secondaryColor !important;
  }
}
.marron--color {
  color: $maronColor;
  border-color: $maronColor !important;
}
.break-word {
  word-break: break-word;
}
.cursor-pointer {
  cursor: pointer;
}
.btn--primary {
  text-transform: capitalize;
  background-color: $primaryColor;
  color: #fff;
  border: 0;
  padding: 5% 10%;
  border-radius: 5px;
}
.btn--secondary {
  text-transform: capitalize;
  background-color: $secondaryColor;
  color: #fff;
  border: 0;
  padding: 5% 10%;
  border-radius: 5px;
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.text-end {
  text-align: end;
}
.MuiButton-root {
  font-weight: 600 !important;
  text-transform: capitalize !important;
  font-family: Montserrat-Medium !important;
}

#alert-dialog-description {
  font-family: Montserrat-Regular;
}

.page-item.active .page-link {
  border-color: $secondaryColor;
  background-color: $secondaryColor;
  font-family: Montserrat-Medium;
}
.text-danger {
  color: #f44336 !important;
}
.arabicFont {
  font-family: NeoSansArabicMedium !important;
}
.match-name-height {
  height: 75px;
}

.page-item .page-link {
  color: $primaryColor;
}
.fav {
  color: $favoiritIcon;
  font-size: 28px !important;
}
.nonfav {
  color: $taupeGray;
  font-size: 28px !important;
}

.card {
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
  border: none;
}
.subTitle {
  font-size: 35px;
  color: $secondaryColor;
  font-family: Montserrat-SemiBold, NeoSansArabicMedium;
}
.link {
  color: $primaryColor;
  font-family: Montserrat-SemiBold, NeoSansArabicMedium;
  font-size: 19px;
  display: inline-block !important;
}

.primary,
.txt-primary {
  color: $primaryColor !important;
}

.secondary {
  color: $secondaryColor;
}

.saved_icon {
  color: $favoiritIcon;
}

.borderRaduis {
  outline: none !important;
  border-radius: 28px !important;
}
button:focus {
  outline: none;
}
.border_right {
  background-color: lightgray;
  height: 1.5em;
  width: 0.1em;
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0 1em;
}
button:focus,
.no-outline {
  outline: 0;
}
.mt-160 {
  margin-top: 20px;
}
.error-message {
  color: red;
  padding-inline-start: 10px;
  font-size: 12px;
}

// MUI changes ::

// Text Fields
body {
  .MuiFormHelperText-root.Mui-error {
    font-size: 0.75rem !important;
  }
  .MuiInputLabel-outlined {
    transform: translate(17px, 17px) scale(1);
  }
  .PrivateNotchedOutline-root-1 {
    left: -8px;
  }
  .MuiSvgIcon-root {
    width: 0.8em;
    height: 0.8em;
  }
  MuiFormHelperText-root.Mui-error {
    font-size: 0.75rem !important;
  }
  .MuiOutlinedInput-input {
    padding: 15px;
  }

  .MuiOutlinedInput-root {
    border-radius: 17px;
    fieldset {
      border: 2px solid $lightTextColor;
    }
    &.Mui-focused {
      fieldset {
        border-width: 2px;
        border-color: $secondaryColor !important;
        legend {
          font-weight: 400;
        }
      }
    }
  }

  &[dir="rtl"] {
    .MuiInputLabel-formControl {
      right: 30px;
    }
    .MuiInputLabel-outlined {
      transform: translate(14px, 17px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }
    }
    .PrivateNotchedOutline-legendNotched-4 {
      max-width: 0 !important;
    }
  }
}
