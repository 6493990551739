.popup-notification__wrapper {
  position: fixed;
  z-index: 10;
  top: 70px;
  right: 200px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  padding: 15px 30px;
  width: 440px;
  min-height: 500px;
  box-shadow: 0px 2px 31px 0px #7f323f12;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 
  .company-img,
  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .company-img {
    background-color: rgba(251, 244, 245, 1);
    img {
      object-fit: cover;
    }
  }

  .notification-text {
    padding: 0 10px;
    &-content {
      font-family: "Montserrat-Medium";
      color: rgba(29, 34, 71, 1);
    }
    &-date {
      font-family: "Montserrat-Medium";
      font-size: 12px;
      color: rgba(123, 123, 123, 1);
    }
  }
  .MuiListItem-gutters {
    padding: unset;
    &:hover {
      border-radius: 20px;
    }
  }
  .view-all-notification {
    text-transform: capitalize;
    font-family: "Montserrat-SemiBold";
    color: rgba(29, 34, 71, 1);
    font-size: 14px;
    border-top: 1px solid rgba(216, 216, 216, 1);
    padding-top: 10px;
  }
  .notification-row{
    background-color: unset !important;
    &:hover{
      background-color: rgba(0, 0, 0, 0.08) !important;
    }
  }
  .notification-unread {
    background-color: #fae2e2 !important;
    border-radius: 12px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      height: 12px;
      width: 12px;
      background-color: rgba(234, 134, 133, 1);
      border-radius: 12px;
      top: 40%;
      left: -18px;
    }
  }
  .no-notification{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 400px;
    svg{
      font-size: 150px;
      fill:#86364e47
    }
    p{
      color:rgba(134, 54, 78, 1);
      font-weight: bold;
      font-size: 15px;
    }
  }
}

.rtl{
  .popup-notification__wrapper{
    right: unset;
    left: 140px;
  }
  .notification-unread {
    position: relative;
    &:before {
      content: "";
     left:unset;
      right: -18px;
    }
  }
  .notification-text-date{
    display: block;
    direction: ltr !important;
  }
}