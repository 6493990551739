body[dir=rtl] {
  font-family: NeoSansArabic;
  text-align: right;
  .bold_font {
    font-family: NeoSansArabicBold;
  }

  .medium_font,
  .semi_bold_font {
    font-family: NeoSansArabicMedium;
  }

  .regular_font {
    font-family: NeoSansArabic;
  }
  .country-list {
    direction: rtl;
    text-align: right;
  }

  .react-tel-input {
    position: relative;
    &::before {
      content: "الهاتف";
      position: absolute;
      top: -8px;
      right: 19px;
      right: 10px;
      display: block;
      background: white;
      padding: 0 5px;
      font-size: 0.8rem;
      width: 34px;
      z-index: 999;
      color: #6b6b6b;
    }
    .form-control + div:before {
      display: none;
    }
  }

  .help-tip {
    left: 5px;
    right: auto;
    &:hover ul {
      text-align: right !important;
      left: 0 !important;
      right: auto !important;
      position: absolute !important;
      z-index: 9999 !important;
    }
  }
}

.MuiDialog-paper[dir="rtl"] {
  text-align: right;
}

.MuiMenuItem-root[dir="rtl"] , .MuiListItemText-root[dir="rtl"] span{
  font-family: NeoSansArabic !important;
}
