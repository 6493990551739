:root {
  --primary-color: #86364e;
  --secondary-color: #ea8685;
  --dark-text-color: rgba(34, 42, 65, 1);
  --dark-primary-color: rgba(63, 23, 30, 1);
}
// $primaryColor:  rgba(134, 54, 78, 1);
$primaryColor: var(--primary-color);
$secondaryColor: var(--secondary-color);
$darkPrimaryColor: rgba(69, 27, 39, 1);
// $secondaryColor: rgba(234, 134, 133, 1);
$darkTextColor: #000000;
$darkPrimaryColor: rgba(63, 23, 30, 1);
$lightTextColor: rgba(109, 119, 131, 1);
$lightBorderColor: rgba(151, 151, 151, 0.25);
$taupeGray: rgba(147, 147, 147, 1);
$grayDarkColor: rgba(67, 67, 67, 1);
$dimGray: rgba(110, 110, 110, 1);
$liver: rgba(76, 76, 76, 1);
$darkGray: rgba(172, 171, 171, 1);
$whiteSmoke: rgba(245, 245, 245, 1);
$lightGrayColor: rgba(109, 119, 131, .18);
$shadow: rgba(136, 134, 134, 0.5);
$blackColor: rgba(51, 51, 51, 1);
$favoiritIcon: #ffc838;
$fontExtentions: "eot", "ttf", "woff", "woff2";
$fontFamilies: "Montserrat-Bold", "Montserrat-Medium", "Montserrat-Regular",
  "Montserrat-SemiBold", "cairo-v10-latin-regular";
$ArabicFontFamilies: "NeoSansArabic", "NeoSansArabicBold", "NeoSansArabicMedium";
 $darkColor: rgba(62, 62, 62, 1);
$lightGrey: rgba(219, 219, 219, 1);
$lightBorder: rgba(224, 233, 239, 1);
$maronColor: rgba(177, 58, 78, 1);
$cardBoxShadow: 0px 2px 31px 0px rgba(127, 50, 63, 0.07);
