body[dir="ltr"] {
  .bold_font {
    font-family: Montserrat-Bold;
  }

  .medium_font {
    font-family: Montserrat-Medium;
  }

  .regular_font {
    font-family: Montserrat-Regular;
  }
  .semi_bold_font {
    font-family: Montserrat-SemiBold;
  }
}
