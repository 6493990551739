@import "../../scss/variables";

.footer_primary_bg {
  // background-color: white;
  background-color: $primaryColor;
  .footer_titles {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1;
    color: white;
  }
  p{
    font-size: 1rem !important;
    line-height: 1.5;
    margin-bottom: 0;
    color: white;
  }
 

  .socialMediaCircle {
    border: 2px solid white;
    height: 45px;
    width: 45px;
    color: white;
    margin-inline: 0.5rem;
    transition: all 0.5s;
    &:hover {
      background-color: white;
      border-color: white;
      color: $primaryColor;
    }
    svg{
      font-size: 1.5rem;
    }
  }
  .gradient-line{
    height: 9px;
    background-image: linear-gradient(90deg,#792E49,$secondaryColor,white);
  }
  .gradient-line-ar{
    height: 9px;
    background-image: linear-gradient(270deg,#792E49,$secondaryColor,white);
  }

  &.dark{
    background-color: $primaryColor;
    .socialMediaCircle{
      color: white;
      border-color: white;
      &:hover {
        background-color: white;
        border-color: white;
        color: $primaryColor;
      }
    }

    h6,p{
      color: white;
    }
  }

}
@media only screen and(max-width:991px){
  .footer_primary_bg {
    .socialMediaCircle{
      height: 40px;
      width: 40px;
    }
  }
  .socialMediaCircle {
    border: 2px solid $primaryColor;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: $primaryColor;
    margin-inline: 0.5rem;
    &:hover {
      background-color: $primaryColor;
      border-color: $primaryColor;
      color: white;
    }
    svg{
      font-size: 1.75rem;
    }
  }
  .gradient-line{
    height: 9px;
    width: 100%;
    border-radius: 4px;
    background-image: linear-gradient(90deg,$primaryColor,$secondaryColor,white);
  }
  &.dark{
    background-color: $primaryColor;
    .socialMediaCircle{
      color: white;
      border-color: white;
      &:hover {
        background-color: white;
        border-color: white;
        color: $primaryColor;
      }
    }

    h6,p{
      color: white;
    }
  }

}
@media only screen and(max-width:768px){
  .footer_primary_bg .footer-logo {
   width: 40% !important;
  }
}

