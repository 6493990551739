@import "../../scss/variables";
.navbar-wrapper{
  top: 0;
  z-index: 99;
}

.navbar {
  background-color: $primaryColor;
  padding:0.25rem 0.5rem !important;
  .navbar-logo {
    height: 3.5em;
  }
  .links {
		margin-inline-start: 2rem;
		margin-inline-end: auto;
	}
  .navItem {
    text-decoration: none;
    margin-right: 0.25em;
    margin-left: 0.25em;
    align-self: center;
    font-size: small;
		position: relative;
    color: white !important;
    &.link-nav::after {
      content: '';
      position: absolute;
      width: 0%;
      height: 3px;
      background-color: $secondaryColor;
      bottom: 0;
      right: 0.5rem;
      transition: 0.2s all ease-in-out;
    }
    &:hover,&.active{
      &.link-nav::after {
        width: 45%;
      }
    }
    &.active{
      font-family: Montserrat-Bold;
      .rtl &{
        font-family: NeoSansArabicBold;
      }
    }
  }

	.langBtn {
		color: white;
		text-decoration: none;
		font-size: inherit;
		padding-inline-end: 2.5rem;
    padding-inline-start: 0.25rem;
    padding-bottom: 2rem;
		background-image: url('../../assets/images/lang-arrow-ar.png');
		background-repeat: no-repeat;
		background-position: 100% 3px;
		background-size: 55% 78%;
    font-family: NeoSansArabic !important;
		.rtl & {
      padding-inline-end: 3.8rem;
      padding-bottom: 5px;
			background-image: url('../../assets/images/lang-arrow-en.png');
			background-position: 0 0;
		  background-size: 50% 87%;
      font-family: Montserrat-Medium !important;
		}
    &:hover{
      text-decoration: none;
      color: white;
    }
	}
  .btn:focus{
    box-shadow: none;
  }

  .border_right {
		background-color: $secondaryColor;
		height: 1.5em;
		width: 0.1em;
		display: flex;
		align-items: center;
		align-self: center;
    margin: 0 0.25rem;
	}
  .navbar-toggler {
    border: 0 !important;
    outline: none;
    padding: 0;
  }

  .dropdown {
    button {
      cursor: pointer;
    }
    .btn--primary {
      background-color: transparent;
      color: $darkTextColor;
      &:hover {
        color: $darkTextColor;
      }
      &:focus,
      &:active {
        background-color: transparent;
        color: $darkTextColor;
        outline: none;
        box-shadow: none;
      }
    }
  }
  .unseen-icon{
    position: relative;
    &::before{
      position: absolute;
      top:15%;
      right: 0;
      background-color: rgba(234, 134, 133, 1);
      border:1px solid #fff;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      content:""

    }

  }
  .MuiButton-root{
    color: white;
  }
}
.navbar-light .navbar-nav .nav-link {
  cursor: pointer;
}

.langBtn {
  border-radius: 59%;
  height: 31px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 1em;
  color: $primaryColor;
  background-color: #f3f3f3;
  text-decoration: none;
}

.signNav {
  background-color: transparent;
  border-radius: 22px;
  color: white;
  padding: 6px 12px;
  border: 2px solid $secondaryColor;
}

.right0 {
  right: 0;
}
.left0 {
  left: 0;
}

.forCompanies {
  border: 1px solid rgba(234, 134, 133, 1);
  border-radius: 22px;
  color: rgba(234, 134, 133, 1);
  padding: 10px 19px;
  background-color: transparent;
}

@media only screen and (max-width: 800px) {
  .signNav {
    font-size: 12px !important;
  }
  .navItem {
    line-height: 2em;
  }
  .navbar-collapse {
    text-align: center;
  }
  .forCompanies {
    margin: 1em 0;
  }
}

@media only screen and (min-width: 1000px) {
  .navbar-collapse {
    display: flex;
    justify-content: space-between;
  }
}
body[dir="ltr"] {
  .navbar .nav-link.active {
    font-family: "Montserrat-SemiBold";
  }
  .navbar .navItem {
    font-size: 15px;
  }
}
body[dir="rtl"] {
  .navbar .nav-link.active {
    font-family: "NeoSansArabicBold";
  }
  .navbar .signNav {
    padding: 8px 16px;
  }
}
@media only screen and (max-width: 992px){
  .navbar{
    .links {
			margin: 1rem 0;
			.navItem {
				&:hover,&.active{
          &.link-nav::after {
            width: 0%;
          }
        }
			}
		}
    .log-lang{
      margin-inline-start: auto;
      order: 1;
      flex-direction: row;
    }
    .navbar-toggler{
      order: 2;
    }
    .navbar-collapse{
      order: 3;
      text-align: center;
      margin-bottom: 1rem;
      padding: 0.5rem 1rem;
    }
  }
}